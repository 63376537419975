import React, { useState } from 'react';
import { useMap, useMapEvents, Popup } from 'react-leaflet';
import * as turf from '@turf/turf';
import { fetchCoastlineData, getCardinalDirection } from '../pages/map-utils'; // Adjusted import path
import NewSpotForm from './NewSpotForm'; // Adjusted import path

const MapInteractionHandler = ({
  setClickedPosition,
  setSurfSpots,
  surfSpots,
  clickedPosition,
  fetchSurfSpots,
  spotBeingEditedForLocation,
  setSpotBeingEditedForLocation,
  isUpdatingLocation,
  setIsUpdatingLocation,
  isAddingSpot,
  setIsAddingSpot,
  getFirebaseToken
}) => {
  const map = useMap(); // Get the map instance from react-leaflet
  const [setFormOpen] = useState(false); // State to track if the form is open

  // Function to handle adding a new surf spot
  const handleAddSpot = async (spotData) => {
    setIsAddingSpot(true); // Set the adding spot state to true
    console.log('Adding new spot:', spotData);
    try {
      const token = await getFirebaseToken(); // Get the Firebase token for authentication
      const bounds = map.getBounds(); // Get the current map bounds
      const bbox = `${bounds.getSouth()},${bounds.getWest()},${bounds.getNorth()},${bounds.getEast()}`; // Create a bounding box string

      // Fetch coastline data within the bounding box
      fetchCoastlineData(bbox, (coastline) => {
        const clickedPoint = turf.point([spotData.lng, spotData.lat]); // Create a point for the clicked location
        const nearest = turf.nearestPointOnLine(coastline, clickedPoint); // Find the nearest point on the coastline
        const bearing = turf.bearing(clickedPoint, nearest); // Calculate the bearing from the clicked point to the nearest coastline point
        const cardinalDirection = getCardinalDirection(bearing); // Get the cardinal direction from the bearing

        // Create an updated spot data object with orientation and bearing
        const updatedSpotData = {
          ...spotData,
          orientation: cardinalDirection,
          bearing: bearing.toFixed(2),
        };

        console.log('Updated spot data:', updatedSpotData);

        // Send a POST request to add the new spot
        fetch('https://api-surf-spots.shaman.surf/spots', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(updatedSpotData),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Failed to add new spot');
          })
          .then(() => {
            fetchSurfSpots(); // Refresh the list of surf spots
            setClickedPosition(null); // Reset the clicked position
            setFormOpen(false); // Close the form
            alert('New spot added successfully'); // Show success message
          })
          .catch((error) => {
            console.error('Error adding new spot:', error);
            alert('Error adding new spot'); // Show error message
          })
          .finally(() => {
            setIsAddingSpot(false); // Reset the adding spot state
            setClickedPosition(null); // Ensure clicked position is reset
            console.log('Clicked position after adding spot:', clickedPosition);
          });
      });
    } catch (error) {
      console.error('Error in handleAddSpot:', error);
      alert('Error adding new spot'); // Show error message
      setIsAddingSpot(false); // Reset the adding spot state
      setClickedPosition(null); // Ensure clicked position is reset
    }
  };

  // Function to handle updating the location of an existing surf spot
  const handleUpdateLocation = async (spot, newLat, newLng) => {
    setIsUpdatingLocation(true); // Set the updating location state to true
    console.log('Updating location for spot:', spot, 'New coordinates:', { newLat, newLng });
    try {
      const token = await getFirebaseToken(); // Get the Firebase token for authentication
      const bounds = map.getBounds(); // Get the current map bounds
      const bbox = `${bounds.getSouth()},${bounds.getWest()},${bounds.getNorth()},${bounds.getEast()}`; // Create a bounding box string

      // Fetch coastline data within the bounding box
      fetchCoastlineData(bbox, (coastline) => {
        const newPoint = turf.point([newLng, newLat]); // Create a point for the new location
        const nearest = turf.nearestPointOnLine(coastline, newPoint); // Find the nearest point on the coastline
        const bearing = turf.bearing(newPoint, nearest); // Calculate the bearing from the new point to the nearest coastline point
        const cardinalDirection = getCardinalDirection(bearing); // Get the cardinal direction from the bearing

        // Create an updated spot object with new location, orientation, and bearing
        const updatedSpot = {
          ...spot,
          lat: newLat,
          lng: newLng,
          orientation: cardinalDirection,
          bearing: bearing.toFixed(2),
        };

        console.log('Updated spot data for location update:', updatedSpot);

        // Send a PUT request to update the spot location
        fetch(`https://api-surf-spots.shaman.surf/spots/${spot.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(updatedSpot),
        })
          .then((response) => {
            if (response.ok) {
              setSurfSpots(
                surfSpots.map((s) => (s.id === spot.id ? updatedSpot : s))
              ); // Update the surf spots state with the updated spot
              //alert('Spot location updated successfully'); // Show success message
            } else {
              alert('Failed to update spot location'); // Show error message
            }
          })
          .catch((error) => {
            console.error('Error updating spot location:', error);
            alert('Error updating spot location'); // Show error message
          })
          .finally(() => {
            setIsUpdatingLocation(false); // Reset the updating location state
            setSpotBeingEditedForLocation(null); // Reset the spot being edited for location
          });
      });
    } catch (error) {
      console.error('Error in handleUpdateLocation:', error);
      alert('Error updating spot location'); // Show error message
      setIsUpdatingLocation(false); // Reset the updating location state
      setSpotBeingEditedForLocation(null); // Reset the spot being edited for location
    }
  };

  // Handle map click events
  useMapEvents({
    click: (e) => {
      if (isUpdatingLocation) {
        return; // If updating location, do nothing on click
      }
      const { lat, lng } = e.latlng; // Get the latitude and longitude of the click event
      console.log('Map clicked at:', { lat, lng });
      if (spotBeingEditedForLocation) {
        handleUpdateLocation(spotBeingEditedForLocation, lat, lng); // Update the location of the spot being edited
      } else {
        setClickedPosition({ lat, lng }); // Set the clicked position state
        setFormOpen(true); // Open the form
        console.log('Clicked position set to:', { lat, lng });
      }
    },
  });

  return clickedPosition ? (
    <Popup
      position={[clickedPosition.lat, clickedPosition.lng]}
      onClose={() => {
        setClickedPosition(null); // Reset the clicked position state
        setFormOpen(false); // Close the form
        console.log('Popup closed, clicked position reset');
      }}
    >
      <NewSpotForm
        spot={clickedPosition}
        onAdd={handleAddSpot} // Handle adding a new spot
        onCancel={() => {
          setClickedPosition(null); // Reset the clicked position state
          setFormOpen(false); // Close the form
          console.log('Form cancelled, clicked position reset');
        }}
      />
    </Popup>
  ) : null; // If no clicked position, return null
};

export default MapInteractionHandler;
