import React from 'react';

function SurferInfo() {
    return (
        <div style={{
            position: 'absolute',
            top: '60px',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '24px', // Adjust as needed
            marginTop: '20px', // Adjust as needed
            padding: '20px', // Add padding
            display: 'grid',
            gridTemplateColumns: '1fr 1fr', // Two columns
            gap: '20px' // Space between grid items
        }}>
            <h2 style={{ gridColumn: 'span 2' }}>My Info 🌅</h2>
            <p style={{ gridColumn: 'span 2', fontSize: '15px', width: '600px', margin: '0 auto' }}>
                Help us create a surf report that's uniquely yours! Please take a moment to fill in the details below. <strong style={{ color: 'white' }}>The more information you provide about your quiver, gear, surfing experience, and ideal conditions, the better we can customize your report.</strong> 
            </p>
            <p style={{ gridColumn: 'span 2', fontSize: '15px', width: '600px', margin: '0 auto' }}>
            Feel free to write in long form—don’t hold back on sharing the specifics that make your surf style unique. From your <span style={{ color: 'green' }}>board preferences</span> to the type of <span style={{ color: 'green' }}>waves</span> you love, every detail helps us capture the essence of your perfect session! 🏄‍♂️
            </p>
            <form style={{
                gridColumn: 'span 2',
                width: '600px',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr', // Two columns
                gap: '20px' // Space between grid items
            }}>
                <label style={{ fontSize: '20px', gridColumn: 'span 2' }}>
                    Name:
                    <input type="text" name="name" />
                </label>
                <label style={{ fontSize: '20px' }}>
                    My Quiver 🏹:
                    <textarea 
                        placeholder="Describe all the boards in your quiver. What’s your go-to board for certain conditions? Any custom shapes or special features?" 
                        style={{ height: '150px', width: '90%' }}>
                    </textarea>
                </label>
                <label style={{ fontSize: '20px' }}>
                    My Gear 🔧:
                    <textarea 
                        placeholder="What other gear do you use? Mention wetsuits, leashes, fins, wax preferences, and anything that’s part of your setup." 
                        style={{ height: '150px', width: '90%' }}>
                    </textarea>
                </label>
                <label style={{ fontSize: '20px' }}>
                    My Surfing Experience 🌍:
                    <textarea 
                        placeholder="How long have you been surfing? Share your skill level, favorite breaks, memorable sessions, and any surf travels." 
                        style={{ height: '150px', width: '90%' }}>
                    </textarea>
                </label>
                <label style={{ fontSize: '20px' }}>
                    My Ideal Conditions 🌤️:
                    <textarea 
                        placeholder="Describe your dream surf session! What wave size, direction, wind conditions, and tide work best for you?" 
                        style={{ height: '150px', width: '90%' }}>
                    </textarea>
                </label>
                <button type="submit" style={{ gridColumn: 'span 2', backgroundColor: 'purple', color: 'white', fontSize: '20px' }}>Submit 🚀</button>
            </form>
        </div>
    );
}

export default SurferInfo;
