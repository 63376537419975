// AdventureMap.jsx

import React, { useEffect, useState, useCallback } from 'react'; // Import necessary React hooks
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'; // Import components from react-leaflet for map interaction
import L from 'leaflet'; // Leaflet library for map and marker icons
import 'leaflet/dist/leaflet.css'; // Import Leaflet's default CSS for map styling
import './AdventureMap.css'; // Custom CSS for AdventureMap component
//import { fetchCoastlineData, getCardinalDirection } from './map-utils'; // Utility functions for fetching coastline data and calculating cardinal direction
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import Firebase auth
import MapInteractionHandler from '../components/MapInteractionHandler'; // Import the new component
import EditablePopupContent from '../components/EditablePopupContent'; // Import the new component

// Define default coordinates for New York City
const NEW_YORK_COORDS = [40.7128, -74.0060];

// Component to adjust map view based on user location
const SetViewToUserLocation = ({ zoom }) => {
  const map = useMap(); // Access Leaflet map instance
  useEffect(() => {
    // Check if geolocation is available in the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords; // Get user's latitude and longitude
          map.setView([latitude, longitude], zoom); // Set map view to user's location
        },
        (error) => {
          // If geolocation fails, log the error and set map view to New York
          console.error("Error getting user location:", error);
          map.setView(NEW_YORK_COORDS, zoom);
        }
      );
    } else {
      // Fallback if geolocation is not supported
      console.log("Geolocation is not available");
      map.setView(NEW_YORK_COORDS, zoom);
    }
  }, [map, zoom]); // Dependency array to re-run the effect when map or zoom changes

  return null; // This component doesn't render anything visible
};

// Main AdventureMap component
const AdventureMap = () => {
  const [userLocation, setUserLocation] = useState(NEW_YORK_COORDS); // State to store user's current location
  const [surfSpots, setSurfSpots] = useState([]); // State to store surf spots fetched from API
  const [clickedPosition, setClickedPosition] = useState(null); // State to store map click coordinates
  const [spotBeingEditedForLocation, setSpotBeingEditedForLocation] = useState(null); // State for spot being edited
  const [isUpdatingLocation, setIsUpdatingLocation] = useState(false); // State to track if location is being updated
  const [isAddingSpot, setIsAddingSpot] = useState(false); // State to track if a new spot is being added
  const [isSavingChanges, setIsSavingChanges] = useState(false); // State to track if changes are being saved
  const [isDeletingSpot, setIsDeletingSpot] = useState(false); // State to track if a spot is being deleted

  // Function to fetch the Firebase token
  const getFirebaseToken = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      return await user.getIdToken();
    } else {
      throw new Error("User is not authenticated");
    }
  };

  // Function to fetch surf spots from an external API
  const fetchSurfSpots = useCallback(async () => {
    try {
      const token = await getFirebaseToken(); // Get Firebase token
      const response = await fetch('https://api-surf-spots.shaman.surf/spots', {
        headers: {
          'Authorization': `Bearer ${token}` // Include token in headers
        }
      });
      if (response.ok) {
        const data = await response.json(); // Parse the response JSON
        setSurfSpots(
          data.map(spot => ({
            ...spot, // Spread spot data
            lat: parseFloat(spot.lat), // Ensure latitude is a float
            lng: parseFloat(spot.lng)  // Ensure longitude is a float
          }))
        );
        console.log('Surf spots fetched:', data);
      } else {
        console.error('Failed to fetch surf spots'); // Log an error if the request fails
      }
    } catch (error) {
      console.error('Error fetching surf spots:', error); // Catch and log any fetch errors
    }
  }, []); // Empty dependency array means this function is stable and doesn't change.  

  // Fetch surf spots once when the component mounts
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchSurfSpots(); // Fetch surf spots only when the user is authenticated
      } else {
        console.error('User is not authenticated');
      }
    });
  
    return () => unsubscribe(); // Cleanup the listener when the component unmounts
  }, [fetchSurfSpots]); 
  

  // Get user location using geolocation API on component mount
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords; // Get latitude and longitude
          setUserLocation([latitude, longitude]); // Set user's location in state
          console.log('User location set to:', [latitude, longitude]);
        },
        (error) => {
          console.error("Error getting user location:", error); // Handle geolocation error
        }
      );
    } else {
      console.log("Geolocation is not available");
    }
  }, []);

  // Custom pin icon using an emoji for surf spots
  const pinIcon = L.divIcon({
    html: '📍', // Emoji to use as marker
    className: 'emoji-icon', // Custom class for styling
    iconSize: [40, 40], // Icon size
    iconAnchor: [20, 40], // Anchor point for the icon
    popupAnchor: [0, -40] // Where the popup should appear relative to the icon
  });

  // Function to handle updating a surf spot
  const handleUpdate = async (id, updatedSpot) => {
    setClickedPosition(null);
    setIsSavingChanges(true); // Set saving state to true
    console.log('Updating spot:', id, updatedSpot);
    try {
      const token = await getFirebaseToken(); // Get Firebase token
      const response = await fetch(`https://api-surf-spots.shaman.surf/spots/${id}`, {
        method: 'PUT', // Use PUT method to update a spot
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include token in headers
        },
        body: JSON.stringify(updatedSpot), // Send updated spot data
      });

      if (response.ok) {
        setSurfSpots(
          surfSpots.map(spot => spot.id === id ? { ...spot, ...updatedSpot } : spot)
        ); // Update the state with the modified spot
        console.log('Spot updated successfully:', updatedSpot);
        
        //alert('Spot updated successfully');
      } else {
        alert('Failed to update spot');
      }
    } catch (error) {
      console.error('Error updating spot:', error);
      alert('Error updating spot');
    } finally {
      setIsSavingChanges(false); // Set saving state to false
      console.log('State after update:', { surfSpots, isSavingChanges });
    }
  };

  // Function to handle deleting a surf spot
  const handleDelete = async (id) => {
    setIsDeletingSpot(true); // Set deleting state to true
    console.log('Deleting spot:', id);
    try {
      const token = await getFirebaseToken(); // Get Firebase token
      const response = await fetch(`https://api-surf-spots.shaman.surf/spots/${id}`, {
        method: 'DELETE', // Use DELETE method to remove a spot
        headers: {
          'Authorization': `Bearer ${token}` // Include token in headers
        }
      });

      if (response.ok) {
        setSurfSpots(surfSpots.filter(spot => spot.id !== id)); // Remove the deleted spot from state
        console.log('Spot deleted successfully:', id);
        alert('Spot deleted successfully');
      } else {
        alert('Failed to delete spot');
      }
    } catch (error) {
      console.error('Error deleting spot:', error);
      alert('Error deleting spot');
    } finally {
      setIsDeletingSpot(false); // Set deleting state to false
      console.log('State after delete:', { surfSpots, isDeletingSpot });
    }
  };

  // Function to handle starting the edit location process
  const startEditLocation = (spot) => {
    setSpotBeingEditedForLocation(spot);
    setClickedPosition(null); // Clear clicked position to avoid showing add spot popup
    console.log('Editing location for spot:', spot);
  };

  // Render the AdventureMap component
  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      {/* Display a prompt when editing location */}
      {spotBeingEditedForLocation && !isUpdatingLocation && (
        <div className="prompt-message">
          Click on the map to select a new location for "{spotBeingEditedForLocation.name}"
        </div>
      )}
      {/* Display a general prompt when not editing location */}
      {!spotBeingEditedForLocation && !isUpdatingLocation && (
        <div className="prompt-message general-prompt">
          <strong>Instructions:</strong> <i>Click a location on the map to add a surf spot or edit one of your spots by clicking on one of the red pins</i>
        </div>
      )}
      {/* Show loading overlay during update */}
      {(isUpdatingLocation || isAddingSpot || isSavingChanges || isDeletingSpot) && (
        <div className="loading-overlay">
          <div className="loading-message">
            {isUpdatingLocation ? 'Updating location...' : isAddingSpot ? 'Adding new spot...' : isSavingChanges ? 'Saving changes...' : 'Deleting spot...'}
          </div>
        </div>
      )}
      <MapContainer center={userLocation} zoom={9} style={{ height: '100%', width: '100%' }}> {/* Leaflet map container */}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // Tile layer from OpenStreetMap
          attribution='&copy; OpenStreetMap contributors' // Attribution for OpenStreetMap
          maxZoom={19} // Maximum zoom level
        />
        <TileLayer
          url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png" // Additional layer for nautical features
          attribution='&copy; OpenSeaMap contributors' // Attribution for OpenSeaMap
          maxZoom={19}
        />
        <SetViewToUserLocation zoom={9} /> {/* Adjust the map view to the user's location */}
        <MapInteractionHandler
          setClickedPosition={setClickedPosition}
          setSurfSpots={setSurfSpots}
          surfSpots={surfSpots}
          clickedPosition={clickedPosition}
          fetchSurfSpots={fetchSurfSpots}
          spotBeingEditedForLocation={spotBeingEditedForLocation}
          setSpotBeingEditedForLocation={setSpotBeingEditedForLocation}
          isUpdatingLocation={isUpdatingLocation}
          setIsUpdatingLocation={setIsUpdatingLocation}
          isAddingSpot={isAddingSpot}
          setIsAddingSpot={setIsAddingSpot}
          getFirebaseToken={getFirebaseToken}
        /> {/* Handles interactions like clicking to add a new spot or edit location */}
        {surfSpots.map(spot => ( // Iterate over surf spots and render them on the map
          <Marker
            key={spot.id}
            position={[spot.lat, spot.lng]}
            icon={pinIcon} // Use custom pin icon
          >
            <Popup>
              <EditablePopupContent
                spot={spot}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                setSpotBeingEditedForLocation={startEditLocation}
              /> {/* Popup with editable content for each surf spot */}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default AdventureMap; // Export the AdventureMap component as default
